<template>
  <div class="view-my-courses">
    <section class="py-5">
      <div class="container py-5">
        <h1 class="mb-4">Meus cursos</h1>

        <div v-if="myCourses.length === 0" class="text-center text-secondary">
          <em>Você ainda não adquiriu nenhum curso</em>
        </div>

        <div class="row">
          <div
            v-for="(course, index) in myCourses"
            class="col-lg-3 col-md-6 my-2 d-flex flex-column"
            :key="index"
          >
            <course-card class="flex-fill" :course="course" />
          </div>
        </div>
      </div>
    </section>

    <section class="bg-white py-5">
      <div class="container">
        <h2 class="mb-4">Cursos em destaque</h2>

        <action-dispatcher action="courses/getFeatured">
          <div class="row">
            <div
              v-for="(course, index) in featured"
              class="col-lg-3 col-md-6 my-2 d-flex flex-column"
              :key="index"
            >
              <course-card class="flex-fill" :course="course" />
            </div>
          </div>
        </action-dispatcher>
      </div>
    </section>
  </div>
</template>

<script>
import CourseCard from "../components/courses/CourseCard";
import { mapState } from "vuex";
import get from "lodash/get";

export default {
  components: { CourseCard },
  computed: {
    ...mapState("courses", ["courses", "featured"]),
    myCourses() {
      return get(this.$user, "user.my_courses", []).filter(
        course => course.id !== this.$user.cof.id
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.view-my-courses {
  h1 {
    margin-bottom: 0;
  }
}
</style>
